import React from 'react';

class EventItem extends React.Component{

    render(){

        let caption = 'Open in Instagram';//this.props.item.caption
        
        return(
            <div className="col-4 col-sm-3 col-lg-2 si-item">
                <figure className="figure">
                    <a href={this.props.item.link} title={caption} target="_blank" rel="noopener noreferrer"><img className="img-fluid img-figure" alt={caption} src={this.props.item.url}/></a>
                </figure>
            </div>
        )
    }
}


export default EventItem;