import React from 'react';
import {USERVARS} from './Common.js';
import FeedList from './FeedList';
import FeedSource from '../apis/instagram.js';
import '../assets/css/sampinsta.css';


class App extends React.Component{

    state = {items: [], selectedItem: null, started: false};


    componentDidMount(){

        window.addEventListener('scroll', this.scrollCheck);

            if(!this.isOkToStart(USERVARS.ELEMENT)) return;

            if(USERVARS.ACCESSTOKEN) this.loadFeed();
            else return; 

    }


    loadFeed = async () => {

        this.setState({
            started: true
        });
        
        const response = await FeedSource.get('/me/media?fields=id,caption,permalink,media_url', {
        //const response = await FeedSource.get('/elpollosampo/media/', {
            params: {
                access_token: USERVARS.ACCESSTOKEN,
                limit: USERVARS.FETCHITEMS
            }
        });

        //console.log(response.data.data);

        this.setState({
            items: response.data.data,
            selectedItem: null
        });
    };


    isOkToStart = () => {

        let domelem = document.querySelector(USERVARS.ELEMENT);
        let distance = domelem.getBoundingClientRect();

        return (
            //check position on page with offset for seamless load
            distance.top <= (window.innerHeight+window.innerHeight/4 || document.documentElement.clientHeight+window.innerHeight/4)
        );
    };


    scrollCheck = () => {

            if(this.isOkToStart() && !this.state.started) {
                this.loadFeed();
            }
    };


    render(){

        //low_resolution standard_resolution resolution="thumbnail"

        return(
            <div className="container-fluid">
                <FeedList items={this.state.items} />
            </div>
        )
    }
}

export default App;