import React from 'react';
import FeedItem from './FeedItem';

class FeedList extends React.Component {

    render(){

            if(this.props.items.length === 0) {
                return(
                    <div id="feedList" className="row esd-loading"></div>
                )
            }

        let collection = [];

        this.props.items.forEach((col) => {

            collection.push({url: col.media_url, link: col.permalink, caption: col.caption});

                //if post is an album. doesn't support individual link
                /*if(col.carousel_media) {
                    col.carousel_media.forEach((item) => {
                            if (item.type === 'image') {
                                newItem = item.images[this.props.resolution];
                                newItem.baseUrl = col.link;
                                collection.push(newItem);
                            }
                    });
                }else{}*/
        });

        const renderedList = collection.map((item, index) => {
            return <FeedItem key={index.toString()} item={item}/>
        });

        return(
            <div id="sampInstaList" className="row">{renderedList}</div>
        )
    }
}


export default FeedList;